<template>
  <!-- 角色修改页面 -->
  <moe-page title="角色修改">
    <moe-form ref="roleForm" :model="role" :rules="roleRules">
      <el-form-item label="角色名称" prop="name">
        <el-input
          v-model.trim="role.name"
          placeholder="请输入角色名称"
          clearable
        />
      </el-form-item>
      <el-form-item label="角色权限" prop="menuIds">
        <!-- 角色权限信息 -->
        <div class="auth-list">
          <el-tree
            ref="authTree"
            :data="authList"
            :props="authProps"
            show-checkbox
            check-strictly
            :default-checked-keys="authIds"
            node-key="id"
            :empty-text="emptyText"
            accordion
            highlight-current
            @check="authChange"
          />
        </div>
      </el-form-item>
      <el-form-item label="角色备注" prop="remarks">
        <el-input
          v-model.trim="role.remarks"
          type="textarea"
          :rows="5"
          placeholder="请输入备注"
          clearable
        />
      </el-form-item>

      <template slot="tool">
        <el-button
          type="primary"
          icon="el-icon-upload2"
          :loading="roleLoad"
          @click="roleInfoSubmit"
        >
          {{ roleLoad ? '提交中' : '提交信息' }}
        </el-button>
      </template>
    </moe-form>
  </moe-page>
</template>

<script>
export default {
  name: 'RoleAmend',
  data() {
    //角色信息
    const role = {
      name: '', //角色名称
      menuIds: '', //角色权限
      remarks: '', //角色备注
    };

    //角色数据校验
    const verify = this.$moe_verify.verifyForm;
    const roleRules = {
      name: verify.isExtent(['请输入角色名称', '长度不能超过 1 ~ 10'], '1~10'),
      menuIds: verify.isEmpty('请选择角色权限'),
      remarks: verify.isEmpty('请输入备注'),
    };

    return {
      role, //角色信息
      roleRules, //角色数据校验
      authList: [], //权限列表
      authIds: [], //默认选中ID
      authProps: {
        children: 'childs',
        label: 'title',
      },
      emptyText: '角色权限加载中',
      roleLoad: false, //提交状态
    };
  },
  created() {
    //获取角色详情
    this.getRoleDetail();

    //获取所有菜单列表
    this.getAllAuthList();
  },
  methods: {
    /**
     * 获取角色详情
     **/
    getRoleDetail() {
      // 获取角色ID
      const id = this.$route.query.id;

      // 调用获取角色详情API
      this.$moe_api.ROLE_API.roleDetail({ id }).then((data) => {
        if (data.code == 200) {
          let r = data.result;

          // 回显角色信息
          this.role = {
            id: r.id, //角色ID
            name: r.name, //角色名称
            menuIds: r.menus, //角色权限
            remarks: r.remarks, //角色备注
          };

          this.$nextTick(() => {
            this.authIds = r.menus;
          });
        } else {
          this.$moe_msg.error(data.message);
        }
      });
    },

    /**
     * 获取所有菜单列表
     **/
    getAllAuthList() {
      // 调用获取所有菜单列表API
      this.$moe_api.ROLE_API.allAuthList()
        .then((data) => {
          if (data.code == 200) {
            this.authList = data.result;
          } else {
            this.$moe_msg.error(data.message);
          }
        })
        .catch(() => {
          this.emptyText = '加载失败';
        })
        .finally(() => {
          this.roleLoad = false;
        });
    },

    /**
     * 监听树形菜单
     **/
    authChange(data, { checkedKeys }) {
      let findIndex = checkedKeys.findIndex((id) => data.id === id)
      if (findIndex > -1) {
        this.authList.forEach((item) => {
          if (item.id === data.id) {
            item.childs.forEach((items) => {
              this.$refs['authTree'].setChecked(items, true)
            })
          } else {
            let parentNodeFindIndex = checkedKeys.findIndex((id) => item.id === id)
            let nextSiblingNodeNumber = 0

            checkedKeys.forEach((id) => {
              nextSiblingNodeNumber = item.childs.filter((itemChildsItem) => itemChildsItem.id === id).length
            })

            if (nextSiblingNodeNumber >= 0) {
              item.childs.forEach((itemChildsItem) => {
                if (itemChildsItem.id === data.id) {
                  if (parentNodeFindIndex === -1) {
                    this.$refs['authTree'].setChecked(item, true)
                  }
                }
              })
            }
          }
        })
      } else {
        this.authList.forEach((item, index) => {
          if (item.id === data.id) {  //父级点击取消选中
            item.childs.forEach((itemChildsItem) => {
              this.$refs['authTree'].setChecked(itemChildsItem, false)  //清除所有子级的选中状态
            })
          } else {
            /**
              * 遍历所有childs
              * 查询子级
              */
            item.childs.forEach((itemChildsItem) => {
              if (itemChildsItem.id === data.id) {
                let noNextSiblingNodeNumber = checkedKeys.filter((id) => { return this.authList[index].childs.filter((indexItem) => indexItem.id === id).length })

                if (!noNextSiblingNodeNumber.length) {
                  this.$refs['authTree'].setChecked(item, false)
                }
              }
            })
          }
        })
      }

      this.role.menuIds = this.$refs['authTree'].getCheckedKeys()
    },

    /**
     * 提交角色信息
     **/
    roleInfoSubmit() {
      // 表单校验
      this.$refs['roleForm'].validate(() => {
        this.roleLoad = true;

        // 调用修改角色API
        this.$moe_api.ROLE_API.roleAmend(this.role)
          .then((data) => {
            if (data.code == 200) {
              this.$moe_msg.success('修改成功');
              this.$router.go(-1);
            } else {
              this.$moe_msg.error(data.message);
            }
          })
          .finally(() => {
            this.roleLoad = false;
          });
      });
    },
  },
};
</script>
